import React from "react";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { navigate } from "gatsby";

const SymposiumRegistrationPage = () => {
  const [title, setTitle] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [organization, setOrganization] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [telephone, setTelephone] = React.useState("");
  const [occupation, setOccupation] = React.useState("");
  const [pickupLocation, setPickupLocation] = React.useState("");
  const [otherOccupation, setOtherOccupation] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [registerSuccessfulMessage, setRegisterSuccessfulMessage] = React.useState("");
  const [programmes, setProgrammes] = React.useState({
    'HKMA (with member no.)': '',
    'HKDU (with member no.)': '',
    'HKAM': '',
    'DH': ''
  })
  const [pointsRedemptionDetail, setPointsRedemptionDetail] = React.useState({
    registeredName: '',
    registeredId: ''
  })

  const register = async () => {
    let noQuery = false;
    
    const chosenProgrammes = Object.keys(programmes).filter(key => programmes[key].length>0).map(key => (
      {
        type: key,
        memberId: programmes[key]
      }
    ))

    if (!firstName.length) {
      setAlertMessage("First Name cannot be empty.");
      noQuery = true;
    }

    if (!lastName.length) {
      setAlertMessage("Last Name cannot be empty.");
      noQuery = true;
    }

    if (!organization.length) {
      setAlertMessage("Organization cannot be empty.");
      noQuery = true;
    }

    if((pointsRedemptionDetail.registeredId.length > 0 || pointsRedemptionDetail.registeredName.length) > 0 && (pointsRedemptionDetail.registeredId.length===0 || pointsRedemptionDetail.registeredName.length===0)){
      setAlertMessage("Invalid Name or Registration No.");
    }

    if (!email.length) {
      setAlertMessage("Email cannot be empty.");
      noQuery = true;
    }

    if (!telephone.length) {
      setAlertMessage("Tel cannot be empty.");
      noQuery = true;
    }

    if (!pickupLocation.length) {
      setAlertMessage("Please choose a pickup location.");
      noQuery = true;
    }

    if (!occupation.length) {
      setAlertMessage("G.P. / Specialist cannot be empty.");
      noQuery = true;
    }

    if (occupation === "Other" && !otherOccupation.length) {
      setAlertMessage("Please specify occupation.");
      noQuery = true;
    }

    const query = `
      mutation registerSymposium($pointsRedemptionDetail: PointsRedemptionDetail) {
        register(input: {
          title: "${title.replace(".", "")}",
          firstName: "${firstName}",
          lastName: "${lastName}",
          affiliation: "${organization}",
          email: "${email}",
          phoneNumber: "${telephone}",
          occupation: "${occupation !== "Other" ? occupation : otherOccupation}"
          pointsRedemptionDetail: $pointsRedemptionDetail,
          pickupLocation: "${pickupLocation}"
        }) {
          url
        }
      }
    `;
    if (!noQuery) {
      setRegisterSuccessfulMessage("Registering...");
      setAlertMessage("");

      fetch(process.env.SYMPOSIUM_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: query, variables: {
          pointsRedemptionDetail: {
            ...pointsRedemptionDetail,
            programmes: chosenProgrammes
          }
        } }),
      })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setTitle("");
        setFirstName("");
        setLastName("");
        setOrganization("");
        setEmail("");
        setTelephone("");
        setOccupation("");
        setAlertMessage("");
        setPickupLocation("");
        navigate("/symposium/registration/success", {
          state: { qrcode: res.data.register.url }
        });
      })
      .catch(() => {
        setRegisterSuccessfulMessage("");
        setAlertMessage("Registration unsuccessful due to invalid field value or server problem.")
      });
    }
  }

  return (
    <div className="h-screen w-full">
      <GatsbySeo
        title="Symposium Application | Chiron Medical"
        description="Symposium Application | Chiron Medical"
        // openGraph={{
        //   type: 'website',s
        //   url: data.site.siteMetadata.siteUrl + location.pathname,
        //   title: content.page.opengraph_title,
        //   description: content.page.opengraph_description,
        //   images: [
        //     {
        //       url: content.page.opengraph_images[0].url,
        //       width: content.page.opengraph_images[0].width,
        //       height: content.page.opengraph_images[0].height,
        //       alt: content.page.opengraph_images[0].alt,
        //     },
        //   ],
        // }}
      />
      {/* <Helmet>
        <meta name="keywords" content={content.page.metadata.keywords} />
      </Helmet> */}
      <div className="flex flex-col items-center relative" style={{ fontFamily: "Playfair Display" }}>
        <img
          alt="stream-background"
          src="/icons/symposium/stream-background.svg"
          className="absolute object-cover top-0 w-full h-full -z-10"
        />
        <h1 className="text-deep-blue-600 text-sm-h2 mx-sm-subtopic text-center lg:mx-0 leading-8 lg:text-lg-h2 lg:leading-16 my-sm-content lg:my-lg-subtopic">
          CHIRON MEDICAL SYMPOSIUM REGISTRATION
        </h1>
        <div className="flex flex-col lg:flex-row justify-evenly w-full lg:w-lg-inline xl:w-xl-inline mb-sm-content lg:mb-lg-content">
          <div className="h-full w-inline lg:hidden mx-auto">
            <img alt="middle-banner" src="/images/symposium/middle-date-banner.png" className="h-full w-full" />
          </div>
          <div className="h-full w-inline lg:w-1/2 mx-auto lg:mx-0 lg:my-auto lg:pr-sm-card">
            <div className="flex flex-col h-full justify-between" style={{ fontFamily: "Francois One" }}>
              <div className="w-full flex flex-col mt-sm-content lg:mt-0">
                <h1 className="text-deep-blue-600 text-sm-h4-card lg:text-lg-h4-article">Format</h1>
                <h2 className="text-deep-blue-600 whitespace-pre-line text-sm-h3-article lg:text-30px">
                  {`Online Platform (Zoom)`}
                </h2>
              </div>
              <div className="w-full flex flex-col mt-sm-subtopic lg:mt-lg-subtopic">
                <h1 className="text-deep-blue-600 text-sm-h4-card lg:text-lg-h4-article">Theme</h1>
                <h2 className="text-deep-blue-600 text-sm-h3-article lg:text-30px">Multi-Specialties Update</h2>
              </div>
              <div className="w-full flex flex-col mt-sm-subtopic lg:mt-lg-subtopic">
                <h1 className="text-deep-blue-600 text-sm-h4-card lg:text-lg-h4-article">Target Audiences</h1>
                <h2 className="text-deep-blue-600 whitespace-pre-line text-sm-h3-article lg:hidden">
                  {`Doctors (GPs/ Specialists),\nNurses and\nAllied Health Professionals`}
                </h2>
                <h2 className="text-deep-blue-600 whitespace-pre-line text-sm-h3-article lg:text-30px hidden lg:block">
                  {`Doctors (GPs/ Specialists),\nNurses and Allied Health Professionals`}
                </h2>
              </div>
            </div>
          </div>
          <div className="h-full w-1/2 hidden lg:block my-auto lg:pl-sm-card">
            <img alt="middle-banner" src="/images/symposium/middle-date-banner.png" className="h-full w-full" />
          </div>
        </div>
        <div className="flex flex-col w-inline lg:w-lg-inline xl:w-xl-inline mb-sm-content lg:mb-lg-content" style={{ fontFamily: "Francois One" }}>
          <div className="flex flex-col">
            <div className="flex flex-row items-center mb-sm-card">
              <h1 className="text-deep-blue-600 text-sm-h4-article leading-6 lg:text-sm-h2 lg:leading-10">Title</h1>
              <h1 className="text-red-700 text-sm-h4-article leading-6 lg:text-sm-h1 lg:leading-10 ml-1">*</h1>
            </div>
            <div className="flex-row items-center mb-lg-subtopic hidden lg:flex">
              <label className="mr-sm-card lg:mr-lg-card">
                <input type="radio" value="Prof." checked={title === "Prof."} onChange={(e) => setTitle(e.target.value)} />
                <span>Prof.</span>
              </label>
              <label className="mr-sm-card lg:mr-lg-card">
                <input type="radio" value="Dr." checked={title === "Dr."} onChange={(e) => setTitle(e.target.value)} />
                <span>Dr.</span>
              </label>
              <label className="mr-sm-card lg:mr-lg-card">
                <input type="radio" value="Mr." checked={title === "Mr."} onChange={(e) => setTitle(e.target.value)} />
                <span>Mr.</span>
              </label>
              <label className="mr-sm-card lg:mr-lg-card">
                <input type="radio" value="Mrs." checked={title === "Mrs."} onChange={(e) => setTitle(e.target.value)} />
                <span>Mrs.</span>
              </label>
              <label className="mr-sm-card lg:mr-lg-card">
                <input type="radio" value="Ms." checked={title === "Ms."} onChange={(e) => setTitle(e.target.value)} />
                <span>Ms.</span>
              </label>
              <label className="mr-sm-card lg:mr-lg-card">
                <input type="radio" value="Miss." checked={title === "Miss."} onChange={(e) => setTitle(e.target.value)} />
                <span>Miss.</span>
              </label>
            </div>
            <div className="flex flex-row items-center mb-sm-subtopic lg:hidden">
              <label className="mr-sm-card lg:mr-lg-card">
                <input type="radio" value="Prof." checked={title === "Prof."} onChange={(e) => setTitle(e.target.value)} />
                <span>Prof.</span>
              </label>
              <label className="mr-sm-card lg:mr-lg-card">
                <input type="radio" value="Dr." checked={title === "Dr."} onChange={(e) => setTitle(e.target.value)} />
                <span>Dr.</span>
              </label>
              <label className="mr-sm-card lg:mr-lg-card">
                <input type="radio" value="Mr." checked={title === "Mr."} onChange={(e) => setTitle(e.target.value)} />
                <span>Mr.</span>
              </label>
            </div>
            <div className="flex flex-row items-center mb-lg-subtopic lg:hidden">
              <label className="mr-sm-card lg:mr-lg-card">
                <input type="radio" value="Mrs." checked={title === "Mrs."} onChange={(e) => setTitle(e.target.value)} />
                <span>Mrs.</span>
              </label>
              <label className="mr-sm-card lg:mr-lg-card">
                <input type="radio" value="Ms." checked={title === "Ms."} onChange={(e) => setTitle(e.target.value)} />
                <span>Ms.</span>
              </label>
              <label className="mr-sm-card lg:mr-lg-card">
                <input type="radio" value="Miss." checked={title === "Miss."} onChange={(e) => setTitle(e.target.value)} />
                <span>Miss.</span>
              </label>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row mb-sm-subtopic lg:mb-lg-subtopic">
            <div className="flex flex-col w-full lg:w-1/2 lg:pr-lg-card mb-sm-subtopic lg:mb-0">
              <div className="flex flex-row items-center w-full">
                <h1 className="text-deep-blue-600 text-sm-h4-article leading-6 lg:text-sm-h2 lg:leading-10">First Name</h1>
                <h1 className="text-red-700 text-sm-h4-article leading-6 lg:text-sm-h1 lg:leading-10 ml-1">*</h1>
              </div>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="border-b border-solid border-deep-blue-600 h-10 w-full outline-none bg-transparent"
              />
            </div>
            <div className="flex flex-col w-full lg:w-1/2 lg:pl-lg-card">
              <div className="flex flex-row items-center w-full">
                <h1 className="text-deep-blue-600 text-sm-h4-article leading-6 lg:text-sm-h2 lg:leading-10">Last Name</h1>
                <h1 className="text-red-700 text-sm-h4-article leading-6 lg:text-sm-h1 lg:leading-10 ml-1">*</h1>
              </div>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="border-b border-solid border-deep-blue-600 h-10 w-full outline-none bg-transparent"
              />
            </div>
          </div>
          <div className="flex flex-col mb-sm-subtopic lg:mb-lg-subtopic">
            <div className="flex flex-row items-center">
              <h1 className="text-deep-blue-600 text-sm-h4-article leading-6 lg:text-sm-h2 lg:leading-10">Position / Department / Organization</h1>
              <h1 className="text-red-700 text-sm-h4-article leading-6 lg:text-sm-h1 lg:leading-10 ml-1">*</h1>
            </div>
            <input
              type="text"
              value={organization}
              onChange={(e) => setOrganization(e.target.value)}
              className="border-b border-solid border-deep-blue-600 h-10 w-full outline-none bg-transparent"
            />
          </div>
          <div className="flex flex-col mb-sm-subtopic lg:mb-lg-subtopic">
            <div className="flex flex-row items-center">
              <h1 className="text-deep-blue-600 text-sm-h4-article leading-6 lg:text-sm-h2 lg:leading-10">Email</h1>
              <h1 className="text-red-700 text-sm-h4-article leading-6 lg:text-sm-h1 lg:leading-10 ml-1">*</h1>
            </div>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border-b border-solid border-deep-blue-600 h-10 w-full outline-none bg-transparent"
            />
          </div>
          <div className="flex flex-col mb-sm-subtopic lg:mb-lg-subtopic">
            <div className="flex flex-row items-center">
              <h1 className="text-deep-blue-600 text-sm-h4-article leading-6 lg:text-sm-h2 lg:leading-10">Phone Number</h1>
              <h1 className="text-red-700 text-sm-h4-article leading-6 lg:text-sm-h1 lg:leading-10 ml-1">*</h1>
            </div>
            <input
              type="text"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
              className="border-b border-solid border-deep-blue-600 h-10 w-full outline-none bg-transparent"
            />
          </div>
          <div className="flex flex-col mb-sm-subtopic lg:mb-lg-subtopic">
            <div className="flex flex-row items-center mb-sm-card">
              <h1 className="text-deep-blue-600 text-sm-h4-article leading-6 lg:text-sm-h2 lg:leading-10">Occupation</h1>
              <h1 className="text-red-700 text-sm-h4-article leading-6 lg:text-sm-h1 lg:leading-10 ml-1">*</h1>
            </div>
            <div className="flex-row items-center lg:flex hidden">
              <label className="mr-sm-card lg:mr-lg-card">
                <input type="radio" value="G.P." checked={occupation === "G.P."} onChange={(e) => setOccupation(e.target.value)} />
                <span>G.P.</span>
              </label>
              <label className="mr-sm-card lg:mr-lg-card">
                <input type="radio" value="Specialist" checked={occupation === "Specialist"} onChange={(e) => setOccupation(e.target.value)} />
                <span>Specialist</span>
              </label>
              <label className="mr-sm-card lg:mr-lg-card">
                <input type="radio" value="Other" checked={occupation === "Other"} onChange={(e) => setOccupation(e.target.value)} />
                <span>Other</span>
              </label>
              {
                occupation === "Other" && (
                  <input
                    type="text"
                    value={otherOccupation}
                    onChange={(e) => setOtherOccupation(e.target.value)}
                    className="border-b border-solid border-deep-blue-600 h-10 w-full lg:w-inline outline-none bg-transparent"
                  />
                )
              }
            </div>
            <div className="flex flex-col lg:hidden">
              <div className="flex-row items-center">
                <label className="mr-sm-card lg:mr-lg-card">
                  <input type="radio" value="G.P." checked={occupation === "G.P."} onChange={(e) => setOccupation(e.target.value)} />
                  <span>G.P.</span>
                </label>
                <label className="mr-sm-card lg:mr-lg-card">
                  <input type="radio" value="Specialist" checked={occupation === "Specialist"} onChange={(e) => setOccupation(e.target.value)} />
                  <span>Specialist</span>
                </label>
              </div>
              <div className="flex-row items-center mt-lg-card">
                <label className="mr-sm-card lg:mr-lg-card">
                  <input type="radio" value="Other" checked={occupation === "Other"} onChange={(e) => setOccupation(e.target.value)} />
                  <span>Other</span>
                </label>
                {
                  occupation === "Other" && (
                    <input
                      type="text"
                      value={otherOccupation}
                      onChange={(e) => setOtherOccupation(e.target.value)}
                      className="border-b border-solid border-deep-blue-600 h-10 w-full lg:w-inline outline-none bg-transparent"
                    />
                  )
                }
              </div>
            </div>
          </div>
          <div className="flex flex-col mb-sm-subtopic lg:mb-lg-subtopic">
            <div className="flex flex-row items-center mb-sm-card">
              <h1 className="text-deep-blue-600 text-sm-h4-article leading-6 lg:text-sm-h2 lg:leading-10">CME</h1>
            </div>
            <div className="flex-row">
              <h1 className="text-deep-blue-600 text-sm-h4-article leading-6 lg:text-sm-h2 lg:leading-10">Full name in BLOCK letter (with surname first) </h1>
              <input
                type="text"
                value={pointsRedemptionDetail.registeredName}
                onChange={(e) => setPointsRedemptionDetail({...pointsRedemptionDetail, registeredName: e.target.value})}
                className="border-b border-solid border-deep-blue-600 h-10 w-full outline-none bg-transparent"
              />
            </div>
            <div className="flex-row items-center mt-lg-card">
              <label className="mr-sm-card lg:mr-lg-card">
                <input type="checkbox" readOnly checked={pointsRedemptionDetail.registeredId.length>0} />
                <span>MCHK Reg No.</span>
              </label>
              <input
                type="text"
                value={pointsRedemptionDetail.registeredId}
                onChange={(e) => setPointsRedemptionDetail({...pointsRedemptionDetail, registeredId: e.target.value})}
                className="border-b border-solid border-deep-blue-600 h-10 w-full lg:w-inline outline-none bg-transparent"
              />
            </div>
            <h1 className="my-6 text-deep-blue-600 text-sm-h4-article leading-6 lg:text-sm-h2 lg:leading-10">
              Please check on your MCHK CME Administrator or CME Programme(s) you have enrolled
            </h1>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-12">
              <div>
                <label className="mr-sm-card lg:mr-lg-card">
                  <input type="checkbox" readOnly checked={programmes['HKMA (with member no.)'].length>0}/>
                  <span>HKMA (with member no.)</span>
                </label>
                <input
                  type="text"
                  value={programmes['HKMA (with member no.)']}
                  onChange={(e) => setProgrammes({...programmes, 'HKMA (with member no.)': e.target.value})}
                  className="border-b border-solid border-deep-blue-600 h-10 w-full lg:w-inline outline-none bg-transparent"
                />
              </div>
              <div>
                <label className="mr-sm-card lg:mr-lg-card">
                  <input type="checkbox" readOnly checked={programmes['HKDU (with member no.)'].length>0} />
                  <span>HKDU (with member no.)</span>
                </label>
                <input
                  type="text"
                  value={programmes['HKDU (with member no.)']}
                  onChange={(e) => setProgrammes({...programmes, 'HKDU (with member no.)': e.target.value})}
                  className="border-b border-solid border-deep-blue-600 h-10 w-full lg:w-inline outline-none bg-transparent"
                />
              </div>
              <div>
                <label className="mr-sm-card lg:mr-lg-card">
                  <input type="checkbox" readOnly checked={programmes['HKAM'].length>0} />
                  <span>HKAM</span>
                </label>
                <input
                  type="text"
                  value={programmes['HKAM']}
                  onChange={(e) => setProgrammes({...programmes, 'HKAM': e.target.value})}
                  className="border-b border-solid border-deep-blue-600 h-10 w-full lg:w-inline outline-none bg-transparent"
                />
              </div>
              <div>
                <label className="mr-sm-card lg:mr-lg-card">
                  <input type="checkbox" readOnly checked={programmes['DH'].length>0} />
                  <span>DH</span>
                </label>
                <input
                  type="text"
                  value={programmes['DH']}
                  onChange={(e) => setProgrammes({...programmes, 'DH': e.target.value})}
                  className="border-b border-solid border-deep-blue-600 h-10 w-full lg:w-inline outline-none bg-transparent"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <h1 className="my-6 text-deep-blue-600 text-sm-h4-article leading-6 lg:text-sm-h2 lg:leading-10">
              Conference Bag Pick-up Point
            </h1>
            <span className="mb-4">Pick-up period of the conference bag: 8 March – 8 April 2021</span>
            <span className="mb-12">Choose your preferred location</span>
            <ul className="space-y-8">
            {
              [
                {
                  name: 'Causeway Bay (Chiron Medical Head Office)',
                  address: '25/F, Progressive Commercial Building, No. 9 Irving Street, Causeway Bay, Hong Kong',
                  openingHours: [
                    'Mon - Fri: 10am - 12:30pm, 3pm - 5:30pm',
                    'Sat - Sun: Closed'
                  ]
                },
                {
                  name: 'Tsim Sha Tsui (Bamboos Professional Nursing Services Limited)',
                  address: 'Room 204, 2/F, Wing On Plaza, 62 Mody Road, Tsim Sha Tsui, Kowloon',
                  openingHours: [
                    'Mon - Sat: 9am - 6pm',
                    'Sun: Closed'
                  ]
                },
                {
                  name: 'Kwun Tong (Hong Kong Health Care Federation)',
                  address: '9/F, South Asia Commercial Centre, 64 Tsun Yip Street, Kwun Tong, Kowloon',
                  openingHours: [
                    'Mon - Fri: 1:30pm - 10pm',
                    'Sat - Sun: 8:30am - 5pm'
                  ]
                }
              ].map(item => (
                <li className="flex flex-col space-y-4" key={item.name}>
                  <label className="flex items-center">
                    <input type="radio" value={item.name} checked={item.name===pickupLocation} onChange={(e) => setPickupLocation(e.target.value)}/>
                    <span>{item.name}</span>
                  </label>
                  <div className="ml-10">
                    <h4>Address:</h4>
                    <span>{item.address}</span>
                  </div>
                  <div className="ml-10">
                    <h4>Openning Hours:</h4>
                    <ul>
                      { item.openingHours.map(availability => <li key={availability}>{availability}</li>)}
                    </ul>
                  </div>
                </li>
              ))
            }
            </ul>
          </div>
          <div className="flex flex-col">
            <button onClick={() => register()} className={`h-10 lg:h-14 font-bold leading-loose cursor-pointer rounded-custom outline-none bg-deep-blue-600 w-fit-content mt-lg-card mx-auto lg:mx-0`}>
              <div className="flex flex-row justify-center mx-sm-subtopic lg:mx-lg-content h-full items-center">
                <h2 className="text-sm-h3-card lg:text-lg-h4-article tracking-wider text-white leading-4" style={{ fontFamily: "Francois One" }}>
                  Register Now
                </h2>
              </div>
            </button>
            {
              alertMessage && (
                <h2 className="text-sm-h3-card lg:text-lg-h4-article tracking-wider text-center lg:text-left text-red-700 leading-4 mt-sm-card lg:mt-lg-card" style={{ fontFamily: "Francois One" }}>
                  { alertMessage }
                </h2>
              )
            }
            {
              registerSuccessfulMessage && (
                <h2 className="text-sm-h3-card lg:text-lg-h4-article tracking-wider text-center lg:text-left text-green-700 leading-4 mt-sm-card lg:mt-lg-card" style={{ fontFamily: "Francois One" }}>
                  { registerSuccessfulMessage }
                </h2>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default SymposiumRegistrationPage;

